var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c0db67343539962f2e7a96155189f704327e43bc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

/**
 * クライアント上でのSentryの設定。
 * ここに追加する設定は、ユーザーがブラウザでページを読み込むたびに使用されます。
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */
const sentryConfigForClient = {
    /** DSN (Data Source Name) - Sentryプロジェクトを識別するためのユニークキー */
    dsn: 'https://8cfcd7fec9c25868febd6b8b783f9396@o465881.ingest.us.sentry.io/4506946523299840',
    /** 本番環境でこの値を調整するか、より細かい制御が必要な場合はtracesSamplerを使用します */
    tracesSampleRate: 1,
    /** このオプションをtrueに設定すると、Sentryのセットアップ中にコンソールに役立つ情報が表示されます。 */
    debug: false,
    /** エラー発生時のリプレイサンプルレートを設定します */
    replaysOnErrorSampleRate: 1.0,
    /**
     * セッションリプレイのサンプルレートを10%に設定します。
     * 開発中は100%にしておき、本番環境ではより低いレートでサンプリングすることが望ましいです
     */
    replaysSessionSampleRate: 0.1,
    /** Sentryセッションリプレイ機能を使用しない予定の場合、このオプションは削除してください： */
    integrations: [
        /**リプレイの追加設定 */
        Sentry.replayIntegration({
            /** すべてのテキストをマスク */
            maskAllText: true,
            /** すべてのメディアをブロック */
            blockAllMedia: true,
        }),
    ],
};

Sentry.init(sentryConfigForClient);
